<template>
  <div class="home">
    <div>
      <img src="../../../public/images/xqy/长电小方wifi/images/小方块_01.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/长电小方wifi/images/小方块_02.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/长电小方wifi/images/小方块_03.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/长电小方wifi/images/小方块_04.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/长电小方wifi/images/小方块_05.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/长电小方wifi/images/小方块_06.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/长电小方wifi/images/小方块_07.png" lazy width="100%" height="100%">
    </div>
    <foot></foot>
    <navigation></navigation>
  </div>
</template>

<script>
import elMenu from '../../../src/components/elMenu'
import foot from '../../../src/components/foot'
import navigation from '../../../src/components/navigation'

export default {
  name: "长电小方WiFi-VO14T",
  components: {
    elMenu, foot, navigation
  },
}
</script>

<style scoped>

</style>